import react from "react";
import bonnet from "./imageProduit/bonnet_reverse.jpg";
import { Image, Title } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

import logo from "./imageProduit/logo.png";
function Error() {
  return (
    <>
      <div
        style={{
          fontFamily: "Sequel Sans",
          color: "rgb(49 41 41);",
          textAlign: "center",
        }}
      >
        <Title style={{ lineHeight: "0.8em" }}>
          {" "}
          Erreur au moment du paiement, réessayez. Back to <a href="/">home</a>
        </Title>
      </div>
    </>
  );
}
export default Error;
