import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);


  const addToCart = (product, quantity) => {
    setCartItems(prevItems => {
      // Vérifier si le produit est déjà dans le panier
      const existingProductIndex = prevItems.findIndex(item => item.id === product.id);
  
      if (existingProductIndex >= 0) {
        // Si le produit existe déjà, mettez à jour la quantité
        const newCartItems = [...prevItems];
        newCartItems[existingProductIndex] = {
          ...newCartItems[existingProductIndex],
          quantity: newCartItems[existingProductIndex].quantity + quantity
        };
        return newCartItems;
      } else {
        // Si le produit n'existe pas, ajoutez-le avec la quantité spécifiée
        return [...prevItems, { ...product, quantity }];
      }
    });
  };
  

    const incrementQuantity = (productId) => {
    setCartItems(currentItems =>
        currentItems.map(item =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
        )
    );
    };

    const decrementQuantity = (productId) => {
        setCartItems(currentItems =>
          currentItems.reduce((acc, item) => {
            if (item.id === productId) {
              if (item.quantity > 1) {
                // Décrémenter la quantité
                return [...acc, { ...item, quantity: item.quantity - 1 }];
              }
              // Ne rien faire si la quantité est déjà à 0 ou 1
              return acc;
            }
            // Ajouter l'article inchangé
            return [...acc, item];
          }, [])
        );
      };
  return (
    <CartContext.Provider value={{ cartItems, addToCart, incrementQuantity, decrementQuantity }}>
      {children}
    </CartContext.Provider>
  );
};
