import React, { useContext } from "react";
import { CartContext } from "./CartContext";
import HeaderSimple from "./HeaderSimple";
import StripeCheckoutButton from "./StripeCheckoutButton";
import { Title } from "@mantine/core";
import "./App.css";

const CartDisplay = () => {
  const { cartItems, incrementQuantity, decrementQuantity } =
    useContext(CartContext);

  return (
    <>
      <HeaderSimple color="black" inCircle="Shop" right="cart*" />
      <Title style={{ fontFamily: "Sequel Sans", textAlign: "center" }}>
        Cart*
      </Title>
      <div>
        {cartItems.length > 0 ? (
          cartItems.map((item) => (
            <div key={item.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src={item.image[0]}
                  style={{ width: "240px", height: "240px" }}
                  alt={item.nom}
                />
                <div>
                  <p
                    style={{
                      fontFamily: "Sequel Sans",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      transform: "translate(-3em, -1em)",
                    }}
                  >
                    {item.nom} <br />
                  </p>
                  <p
                    style={{
                      fontFamily: "Sequel Sans light",
                      fontSize: "0.7rem",
                      fontWeight: "bold",
                      transform: "translate(-4em, -2.7em)",
                    }}
                  >
                    {item.prix} <br />
                  </p>
                  <div
                    class="spinner"
                    style={{ transform: "translate(-2em, -1em)" }}
                  >
                    <button
                      class="minus"
                      onClick={() => decrementQuantity(item.id)}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      class="value"
                      value={item.quantity}
                      readOnly
                    />
                    <button
                      class="plus"
                      onClick={() => incrementQuantity(item.id)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <StripeCheckoutButton item={cartItems} />
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            <p style={{ fontFamily: "Sequel Sans" }}>Your cart is empty*</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CartDisplay;
