// App.js
import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import HeaderSimple from './HeaderSimple';
import ProduitListe from './ProduitListe';
import Portfolio from './Portfolio';
import Home from './Home';
import Info from './Info';
import Aboutme from './Aboutme';
import CartDisplay from './CartDisplay';
import Succeed from './Succeed';
import Error from './Error';
import Maintenance from './Maintenance';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<ProduitListe />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/info" element={<Info/>} />
          <Route path="*" element={<Home />} />
          <Route path="/aboutme" element={<Aboutme />} />
          <Route path="/cart" element={<CartDisplay />} />
          <Route path="/success" element={<Succeed />} />
          <Route path="/error" element={<Error />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
