import { Title } from "@mantine/core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HeaderSimple from "./HeaderSimple";

function Info() {
  return (
    <>
      <HeaderSimple color="black" inCircle="Info" right="portfolio*" />
      <body style={{ height: "92vh" }}>
        <div
          className="Info1"
          style={{
            paddingLeft: "4rem",
            fontSize: "2.3rem",
            letterSpacing: "-0.6px",
            fontFamily: "Sequel Sans",
            height: "31vh",
            paddingTop: "3em",
          }}
        >
          <p className="noInterligne">
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Preview.
            </Link>
          </p>
          <p className="noInterligne">
            <Link
              to={"/aboutme"}
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              About me.
              <FontAwesomeIcon icon={faPlus} />
            </Link>
          </p>
          <p className="noInterligne">
            <Link
              to={"/shop"}
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Shop.
            </Link>
          </p>
        </div>
        <div
          style={{
            background: "black",
            color: "white",
            paddingLeft: "4rem",
            letterSpacing: "-0.6px",
            fontFamily: "Sequel Sans",
            height: "61vh",
          }}
        >
          <p style={{ fontSize: "2.3rem", paddingTop: "1em" }}>Contact.</p>
          <p style={{ fontSize: "1rem", marginTop: "-1.3em" }}>@standsaan</p>
          <p style={{ fontSize: "1rem", marginTop: "-1.3em" }}>
            standsaan@gmail.com
          </p>
          <p style={{ fontSize: "1rem", marginTop: "-1em" }}>
            <FontAwesomeIcon icon={faPlus} />
          </p>
          <p style={{ fontSize: "2.3rem" }}>Services</p>
          <p style={{ fontSize: "1rem", marginTop: "-2.3em" }}>
            Custom typography
          </p>
        </div>
      </body>
    </>
  );
}

export default Info;
