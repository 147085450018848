import React, { useContext, useState } from "react";
import {
  Card,
  Image,
  Text,
  Group,
  SimpleGrid,
  Button,
  Accordion,
  Tooltip,
  NumberInput,
} from "@mantine/core";
import produits from "./produitData";
import HeaderSimple from "./HeaderSimple";
import StripeCheckoutButton from "./StripeCheckoutButton";
import { loadStripe } from "@stripe/stripe-js";
import { CartContext } from "./CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import "./App.css";

const ProduitCard = ({ produit, onQuantityChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [value, setValue] = useState(0);

  const toggleIcon = () => {
    setIsExpanded(!isExpanded);
  };

  const changeImage = () => {
    setImageIndex((prevIndex) => (prevIndex + 1) % produit.image.length);
  };

  const handleIncrement = () => {
    const newQuantity = value < 10 ? value + 1 : value;
    setValue(newQuantity);
    onQuantityChange(produit.id, newQuantity);
  };

  const handleDecrement = () => {
    const newQuantity = value > 0 ? value - 1 : 0;
    setValue(newQuantity);
    onQuantityChange(produit.id, newQuantity);
  };

  return (
    <div>
      <Card width="100%">
        <Card.Section style={{ textAlign: "center" }}>
          <Image
            src={produit.image[imageIndex]}
            height={390}
            alt={produit.nom}
            style={
              {
                // border: "1px black",
                // borderRadius: "26px",
                // boxShadow: "4.6px 9.3px 9.3px 9.3px hsl(0deg 0% 0% / 0.13)",
              }
            }
            onClick={changeImage}
          />
        </Card.Section>
        <Group justify="space-between">
          <div
            style={{
              fontFamily: "Sequel Sans",
              transform: "translate(4em, -4em)",
              width: "16em",
              height: produit.bricolage ? "6em" : "4em",
            }}
          >
            <p
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {produit.nom}
            </p>
            <p
              style={{
                fontSize: "0.7rem",
                fontWeight: "bold",
                transform: "translateY(-2.1em)",
              }}
            >
              {produit.prix}
            </p>
            <p style={{ transform: "translate(0, -2.3em)" }}>
              <FontAwesomeIcon
                icon={isExpanded ? faMinus : faPlus}
                size="xs"
                style={{
                  color: "#000000",
                }}
                onClick={toggleIcon}
              />
            </p>
            <div
              className="spinner"
              style={{ transform: " translate(11em, -4.7em)" }}
            >
              <button className="minus" onClick={handleDecrement}>
                -
              </button>
              <input type="text" className="value" value={value} readOnly />
              <button className="plus" onClick={handleIncrement}>
                +
              </button>
            </div>
            {isExpanded && (
              <div
                style={{
                  fontSize: "0.7rem",
                  transform: "translateY(-8.7em)",
                }}
              >
                <ul style={{ listStyle: "none", padding: "0" }}>
                  <li>25%Mohair</li>
                  <li>14%Nylon</li>
                  <li>15%Wool</li>
                  <li>36%Acrylic</li>
                </ul>
              </div>
            )}
          </div>
        </Group>
      </Card>
    </div>
  );
};

function ProduitListe() {
  const { addToCart } = useContext(CartContext);
  const [selectedQuantities, setSelectedQuantities] = useState({});

  const handleQuantityChange = (produitId, quantity) => {
    setSelectedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [produitId]: quantity,
    }));
  };

  const handleAddToCart = () => {
    Object.entries(selectedQuantities).forEach(([produitId, quantity]) => {
      if (quantity > 0) {
        const produit = produits.find((p) => p.id === produitId);
        addToCart(produit, quantity);
      }
    });

    setSelectedQuantities({});
  };

  return (
    <>
      <HeaderSimple
        color="black"
        inCircle="Info"
        right="cart*"
        style={{ marginBottom: "2em" }}
        bc={"white"}
      />
      <SimpleGrid cols={2} spacing="lg" mt={"4em"}>
        {produits.map((produit) => (
          <ProduitCard
            key={produit.id}
            produit={produit}
            onQuantityChange={handleQuantityChange}
          />
        ))}
      </SimpleGrid>
      <Group justify="space-between" mt="md" mb="xs">
        <button
          onClick={handleAddToCart}
          style={{
            fontFamily: "Sequel Sans",
            width: "100%",
            backgroundColor: "black",
            color: "white",
            height: "2em",
            border: "none",
            fontSize: "1.3rem",
            position: "fixed",
            bottom: "0px",
            cursor: "grab",
          }}
        >
          Add to cart
        </button>
      </Group>
    </>
  );
}

export default ProduitListe;
