import React from "react";
import { SimpleGrid, Title, Group } from "@mantine/core";

export default function Portfolio() {
  return (
    <Group gap="xl">
      <p>Test</p>
      <p>FFEF</p>
    </Group>
  );
}
