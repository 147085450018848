// Header.js
import React, { useContext } from "react";
import { CartContext } from "./CartContext";
import { Link, useLocation } from "react-router-dom";
import { Title } from "@mantine/core";

function HeaderSimple({ color, inCircle, right, bc }) {
  const { cartItems, incrementQuantity, decrementQuantity } =
    useContext(CartContext);
  const location = useLocation();
  const headerStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%", // Largeur totale
    boxSizing: "border-box", // Inclut padding et bordure dans la largeur
    padding: "1.5rem 2.5rem", // Padding général de l'en-tête
    color: color,
    position: "fixed", // Fixe l'en-tête en haut
    top: 0, // Positionne l'en-tête tout en haut de la page
    left: 0, // Alignement à gauche
    right: 0, // Alignement à droite
    zIndex: 1000, // S'assure que l'en-tête reste au-dessus des autres éléments
    height: "5.5em",
    backgroundColor: bc,
  };

  const linkStyle = {
    textDecoration: "none",
  };

  const titleStyle = {
    color: "inherit", // Hériter la couleur du parent
    fontFamily: "Sequel Sans light",
    fontSize: "1.1rem",
    letterSpacing: "-1.5px",
    color: color, // Utiliser la couleur passée en tant que prop
  };

  const quantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const linkTo = right === "cart*" ? "/cart" : "/portfolio";
  const rightIs = right === "cart*" ? `cart( ${quantity} )*` : "portfolio*";
  const destinationPath =
    location.pathname === "/info"
      ? "/"
      : location.pathname === "/cart"
      ? "/shop"
      : "/info";

  return (
    <header style={headerStyle}>
      <div
        className="circle"
        style={{
          justifySelf: "start",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid " + color,
        }}
      >
        <Link to={destinationPath} style={linkStyle}>
          <Title order={4} fw={700} style={titleStyle}>
            {inCircle}
          </Title>
        </Link>
      </div>
      <div style={{ justifySelf: "end" }}>
        <Link to={linkTo} style={linkStyle}>
          <Title order={4} fw={700} style={titleStyle}>
            {rightIs}
          </Title>
        </Link>
      </div>
    </header>
  );
}

export default HeaderSimple;
