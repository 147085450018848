import bonnetface from './imageProduit/bonnet_face.jpg';
import bonnetreverse from './imageProduit/bonnet_reverse.jpg';
import pinkface from './imageProduit/pink_face.png';
import pinkreverse from './imageProduit/pink_pile.png';
import retouche1 from './imageProduit/RETOUCHE 1.jpg';
import retouche2 from './imageProduit/RETOUCHE 2.jpg';
import retouche3 from './imageProduit/Retouche 3.jpg';
import retouche4 from './imageProduit/Retouche 4 .jpg';
import retouche5 from './imageProduit/RETOUCHE 5png.png';
import retouche6 from './imageProduit/RETOUCHE 6  .jpg';
import retouche7 from './imageProduit/Retouche 7 .jpg';
import retouche8 from './imageProduit/RETOUCHE 8 jpg.jpg';
import retouche9 from './imageProduit/Retouche 9 -2.jpg';

const produits = [
  {
    id:"price_1PNwMoIjph6DUuLi6NtxUuhq",
    nom: "Nostalgie Rug",
    image: [retouche4, retouche2, retouche3, retouche1, retouche5, retouche6, retouche7, retouche8, retouche9],
    prix: "€299.99",
    stock: true,
    bricolage: false,
  },
  {
    id: "price_1OG7xLIjph6DUuLi9hUNAZAL",
    nom: "Nostalgie Beanie",
    image: [bonnetface, bonnetreverse],
    prix: "€30.00",
    stock: true,
    bricolage: false,
  },
  {
    id: "price_1Od2azIjph6DUuLifZtioWt5",
    nom: "Nostalgie Beanie",
    image: [pinkface, pinkreverse],
    prix: "€30.00",
    stock: true,
    bricolage: true,
  }
];

export default produits;
