import React from "react";
import { Title } from "@mantine/core";
import background from "./imageProduit/background_home.jpg";
import { Link } from "react-router-dom";
import "./App.css";
import HeaderSimple from "./HeaderSimple";

export default function Home() {
  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <HeaderSimple color="white" inCircle="Info" right="portfolio*" />
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Alignement vertical avec une colonne
          justifyContent: "center",
          alignItems: "center", // Centrer horizontalement
          paddingTop: "7.5em",
        }}
      >
        <Title
          order={4}
          fw={700}
          style={{
            color: "white",
            fontFamily: "Sequel Sans light",
            fontSize: "1rem", // Taille ajustée pour correspondre à l'image
            transform: "translateY(2.3em) translateX(2.3em)", // Décalage vers le haut et vers la droite pour être centré
            letterSpacing: "-1px",
          }}
        >
          Standsaan*
        </Title>
        <Link to="/shop" style={{ textDecoration: "none" }}>
          <Title
            order={1}
            fw={700}
            style={{
              color: "white",
              fontFamily: "Sequel Sans",
              fontSize: "2.3rem", // Taille ajustée pour correspondre à l'image
              transform: "translateY(-0.7em)", // Décalage vers le haut pour être centré
              letterSpacing: "-1.5px",
            }}
          >
            Shop.
          </Title>
        </Link>
      </div>
    </div>
  );
}
