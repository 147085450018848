import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CartProvider } from './CartContext';
import { MantineProvider } from '@mantine/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <script async src="https://js.stripe.com/v3/buy-button.js"></script>
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{
          fontFamily: 'Sequel, sans-serif',
        }}>
      <ToastContainer />
      <CartProvider>
        <App />
      </CartProvider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
