import { Button } from "@mantine/core";
import React, { useContext, useState } from "react";
import { CartContext } from "./CartContext";
import { loadStripe } from "@stripe/stripe-js";

const StripeCheckoutButton = () => {
  const { cartItems } = useContext(CartContext);
  const [loading, setLoading] = useState(false);

  const handleCheckout = async (event) => {
    event.preventDefault();
    setLoading(true);

    const lineItems = cartItems.map((item) => {
      return {
        price: item.id, // ID de votre produit
        quantity: item.quantity,
        adjustable_quantity: {
          enabled: true,
          minimum: 1,
          maximum: 10,
        },
      };
    });

    const response = await fetch("/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: lineItems }),
    });

    const session = await response.json();

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    stripe.redirectToCheckout({ sessionId: session.id });
    setLoading(false);
  };

  return (
    <form onSubmit={handleCheckout}>
      <Button
        variant="filled"
        color="black"
        type="submit"
        style={{
          fontFamily: "Sequel Sans",
          width: "100%",
          backgroundColor: "black",
          color: "white",
          height: "2em",
          border: "none",
          fontSize: "1.3rem",
          position: "fixed",
          bottom: "0px",
          cursor: "grab",
        }}
      >
        {loading ? "Loading..." : "Precommander"}
      </Button>
    </form>
  );
};

export default StripeCheckoutButton;
