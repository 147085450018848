import react from "react";
import bonnet from "./imageProduit/bonnet_reverse.jpg";
import { Image } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

import logo from "./imageProduit/logo.png";
import { Link } from "react-router-dom";
function Succeed() {
  return (
    <>
      <div
        style={{
          fontFamily: "Sequel Sans",
          color: "rgb(49 41 41);",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "right",
            marginTop: "4em",
            letterSpacing: "1px",
          }}
        >
          <h1 style={{ lineHeight: "0.8em" }}>
            Merci <FontAwesomeIcon icon={faCircleCheck} /> &nbsp;
            <br /> pour votre &nbsp;
            <br />
            commande*
          </h1>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image src={bonnet} height={300} />
        </div>
        <div
          style={{
            paddingBottom: "-1em",
            fontSize: "0.9rem",
            fontWeight: "bold",
            transform: "translateY(-2em)",
            lineHeight: "0.9",
          }}
        >
          <p style={{ marginBottom: "-1em" }}>Commande confirmée</p>
          <p style={{ marginBottom: "1em" }}>
            Votre commande sur standsaan.com <br /> a été validée
          </p>
          <p style={{ marginBottom: "2em" }}>
            Un mail de notification vous sera <br /> envoyé à l'envoie de votre
            commande
          </p>
          <p>
            Pour + de questions veuillez <br /> nous contacter ici
          </p>
          <p style={{ marginBottom: "0em" }}>standsaan@gmail.com</p>
          <p
            style={{
              marginBottom: "-4em",
              color: "black !important",
              textDecoration: "none",
            }}
          >
            <Link to="/" color="black" style={{ textDecoration: "none" }}>
              {" "}
              Retour à l'accueil
            </Link>
          </p>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Image src={logo} height={250} />
      </div>
    </>
  );
}
export default Succeed;
