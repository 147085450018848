import React, { useEffect, useState } from "react";
import { Title } from "@mantine/core";
import background from "./imageProduit/aboutme_fonce.jpg";
import { Link } from "react-router-dom";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHyphen } from "@fortawesome/free-solid-svg-icons";
import HeaderSimple from "./HeaderSimple";

function Aboutme() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // L'animation se déclenche après que le composant a été monté
    setLoaded(true);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        position: "fixed",
      }}
    >
      <HeaderSimple color="white" inCircle="Info" right="portfolio*" />

      <div
        style={{
          display: "flex",
          flexDirection: "column", // Alignement vertical avec une colonne
          justifyContent: "center",
          alignItems: "center", // Centrer horizontalement
          marginTop: "8.6em",
        }}
      >
        <Title
          order={1}
          fw={700}
          style={{
            color: "white",
            fontFamily: "Sequel Sans",
            fontSize: "2.3rem",
            transform: "translateX(-2em) translateY(-0.5em)",
          }}
        >
          About me.-
        </Title>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Alignement vertical avec une colonne
          justifyContent: "center",
          alignItems: "center", // Centrer horizontalement
          marginTop: "8.6em",
        }}
      >
        <Title
          fw={700}
          style={{
            color: "white",
            fontFamily: "Sequel Sans",
            fontSize: "1rem",
            transform: "translateY(2em) translateX(-4.3em)",
          }}
        >
          Standsaan*
        </Title>
        <p
          style={{
            color: "white",
            fontFamily: "Sequel Sans",
            fontSize: "1rem",
            transform: "translateY(0.9em) translateX(0.3em)",
          }}
        >
          I am 20 years old and I have been <br />
          passionate about typography for <br />
          years. My dream was to see them <br />
          take shape.
        </p>
      </div>
    </div>
  );
}

export default Aboutme;
